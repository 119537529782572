<template>
	<!-- View Item Modal -->
		<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true">
			<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">View Item</h5><i class="modal-close zwicon-close pull-right display-4" @click='close'></i>
					</div>
					<div class="modal-body">
						<table id="data-table" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <tbody class="table-dark">
                                <tr role="row">
                                    <th colspan="1">Serial No.</th>
                                    <td colspan="3">{{item.serial_no || '-'}}</td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1">Category</th>
                                    <td colspan="3">{{category_name(item.category)}} </td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1">Description</th>
                                    <td colspan="3">{{description_name(item.description) || '-'}} </td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1">Size</th>
                                    <td colspan="3">{{size_name(item.size) || ''}} </td>
                                </tr>
                                <tr role="row">
                                    <th>Pin Conn</th>
                                    <td>{{connection_name(item.pinn_conn) || ''}}</td>
                                    <th>Box Conn</th>
                                    <td>{{connection_name(item.box_conn) || ''}}</td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1">Location</th>
                                    <td colspan="3">{{item.location || '-'}}</td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1">Pending</th>
                                    <td colspan="3"><i v-if="item.pending" class="zwicon-checkmark-circle"></i><i v-if="!item.pending">-</i></td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1">Shot Peening</th>
                                    <td colspan="3"><i v-if="item.SP" class="zwicon-checkmark-circle text-success"></i><i v-if="!item.SP">-</i></td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1">Rework</th>
                                    <td colspan="3"> <i v-if="item.RW" class="zwicon-checkmark-circle text-success"></i><i v-if="!item.RW">-</i></td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1">Dirty</th>
                                    <td colspan="3"> <i v-if="item.dirty" class="zwicon-checkmark-circle text-success"></i><i v-if="!item.dirty">-</i></td>
                                </tr>
                                <tr role="row">
                                    <th>Date Out</th>
                                    <td>
                                        <span v-if='item.in'>{{'-'}}</span>
                                        <span v-if='!item.in'>{{item.state_date}}</span>
                                    </td>
                                    <th>Date In</th>
                                    <td>
                                        <span v-if='item.in'>{{item.state_date}}</span>
                                        <span v-if='!item.in'>{{ '-' }}</span>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1">Time</th>
                                    <td colspan="3">{{item.state_time || '-'}}</td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1">Comments</th>
                                    <td colspan="3">{{item.comments || '-'}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h5 class="mt-5">History</h5>
                        
                        <table id="data-table-history" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <tbody class="table-dark">
                                <tr role="row">
                                    <th colspan="1">Date</th>
                                    <th colspan="1">Ticket No</th>
                                    <th colspan="1">Type</th>
                                    <th colspan="1">Destination</th>
                                </tr>
                                <tr v-for="sauce in history" :key="sauce.shipping_ticket_id"  role="row">
                                    <th colspan="1">{{sauce.ticket_date}}</th>
                                    <th colspan="1"><a target="_blank" :href="'/ticket/view/'+sauce.shipping_ticket_id">{{sauce.shipping_ticket_id}}</a></th>
                                    <th colspan="1">{{sauce.type}}</th>
                                    <th colspan="1">{{sauce.destination}}</th>
                                </tr>
                                
                            </tbody>
                        </table>
					</div>
					<ErrorMessage :message='error' v-if='error.length > 0' />
				</div>
			</div>
		</div>
		<!-- End View Item Modal -->
</template>

<script>
    import { mapGetters } from "vuex";
	import PermissionService from "@/common/permission.service";

	import ErrorMessage from "@/components/General/ErrorMessage";
    import InventoryService from "@/common/inventory.service";

	export default {
		name: 'ViewItem',
		components: {
			ErrorMessage
		},
		props: {
            item: {},
        },
		data(){
			return {
				error: '',
				add_running: false,
                /*item: {
                    id: 1,
                    description: "Mud Motor",
                    size: "5\" / 127mm",
                    serial_no: "36-47751-SDT",
                    pinn_conn: "3 1/2 REG",
                    box_conn: "3-1/2 IF (NC38)",
                    in: true,
                    pending: false,
                    location: "Ram Oil Tools",
                    SP: true,
                    RW: false,
                    dirty: true,
                    date_in: "",
                    date_out: "",
                    time: "",
                    comments: "Lorem ipsum dolor sit amet.",
                },*/
                history:[],
				user: {
					name: '',
					email: '',
					role: ''
				},
				errors: {
					name: '',
					email: '',
					role: ''
				}
			};
		},
        mounted(){
            this.load_history(this.item.inventory_id)
        },
        computed: {
			...mapGetters(['inventory_list', 'user', 'loading','size_list','category_list','connector_list','description_list'])
		},
		methods: {
            load_history(inventory_id){
                InventoryService.get_inventory_history({"inventory_id":inventory_id})
                .then(data => {
                    this.history = [];
                    if(data.success){
                        this.history = data.data.list;
                    }
                });
            },
            description_name(desc_id){
                if(this.description_list.list === undefined) return "";
                let item = this.description_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            category_name(desc_id){
                if(this.category_list.list === undefined) return "";
                let item = this.category_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            size_name(desc_id){
                if(this.size_list.list === undefined) return "";
                let item = this.size_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            connection_name(desc_id){
                if(this.connector_list.list === undefined) return "";
                let item = this.connector_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			close(){
				if(this.add_running) return;
				this.$emit('close');
			},
			action(){
				
				
			},
		}
	}
</script>