<template>
	<!-- Edit Item Modal -->
		<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true">
			<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Edit Item</h5><i class="modal-close zwicon-close pull-right display-4" @click='close'></i>
					</div> 
					<div class="modal-body">
						<table id="data-table" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <tbody class="table-dark">
                                <tr role="row">
                                    <th colspan="1"><label for="temp-item-serialno">Serial No.</label></th>
                                    <td colspan="3"> 
                                        <div class="form-group mb-0">
                                            <input id="temp-item-serialno" class="form-control" v-model='temp_item_serial_no'>
                                        </div>
                                    </td>
                                </tr>
                                 <tr role="row">
                                    <th colspan="1"><label for="temp-item-category">Category</label></th>
                                    
                                    <td colspan="3">
                                        <div class="form-group mb-0">
                                            <select id="temp-item-category" class="form-control" v-model='temp_item_category'>
                                                <option value="false">-</option>
                                                <option v-for="category in category_list.list" v-bind:key="category" :value="category.configuration_id">{{category.name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1"><label for="temp-item-description">Description</label></th>
                                    <td colspan="3">
                                        <div class="form-group mb-0">
                                            <select id="temp-item-description" class="form-control" v-model='temp_item_description'>
                                                <option value="false">-</option>
                                                <option v-for="description in filtered_description_list" v-bind:key="description" :value="description.configuration_id">{{description.name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1"><label for="temp-item-size">Size</label></th>
                                    <td colspan="3">
                                        <div class="form-group mb-0">
                                            <select id="temp-item-size" class="form-control" v-model='temp_item_size'>
                                                <option value="false">-</option>
                                                <option v-for="size in size_list.list" v-bind:key="size" :value='size.configuration_id'>{{size.name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <th><label for="temp-item-pin">Pin Conn</label></th>
                                    <td>
                                        <div class="form-group mb-0">
                                            <select id="temp-item-pin" class="form-control" v-model='temp_item_pinn_conn'>
                                                <option value="false">-</option>
                                                <option v-for="pin_conn in connector_list.list" v-bind:key="pin_conn" :value='pin_conn.configuration_id'>{{pin_conn.name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                    <th><label for="temp-item-box">Box Conn</label></th>
                                    <td>
                                        <div class="form-group mb-0">
                                            <select id="temp-item-box" class="form-control" v-model='temp_item_box_conn'>
                                                <option value="false">-</option>
                                                <option v-for="box_conn in connector_list.list" v-bind:key="box_conn" :value='box_conn.configuration_id'>{{box_conn.name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1"><label for="temp-item-sp">Shot Peening</label></th>
                                    <td colspan="3">
                                        <div class="checkbox-char">
                                            <input id="temp-item-sp" type="checkbox" class="form-control" v-model='temp_item_SP'>
                                            <label for="temp-item-sp">SP</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1"><label for="temp-item-rw">Rework</label></th>
                                    <td colspan="3">
                                        <div class="checkbox-char">
                                            <input id="temp-item-rw" type="checkbox" class="form-control" v-model='temp_item_RW'>
                                            <label for="temp-item-rw">RW</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1"><label for="temp-item-dirty">Dirty</label></th>
                                    <td colspan="3">
                                        <div class="checkbox-char">
                                            <input id="temp-item-dirty" type="checkbox" class="form-control" v-model='temp_item_dirty'>
                                            <label for="temp-item-dirty">D</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1"><label for="temp-item-comments">Comments</label></th>
                                    <td colspan="3"> 
                                        <div class="form-group mb-0">
                                            <input id="temp-item-comments" class="form-control" v-model='temp_item_comments'>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
					</div>
					<ErrorMessage :message='error' v-if='error.length > 0' />
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" @click='action'><i class="zwicon-plus"></i> Update Item</button>
                    </div>
				</div>
			</div>
		</div>
		<!-- End Add Item Modal -->
</template>

<script>
	import PermissionService from "@/common/permission.service";

    import { mapGetters } from "vuex";
	import ErrorMessage from "@/components/General/ErrorMessage";
    import store from "@/store";

	import { EDIT_INVENTORY_ITEM } from "@/store/actions.type";

	export default {
		name: 'EditItem',
		components: {
			ErrorMessage
		},
		props: {
            item: {},
        },
		data(){
			return {
				error: '',
				user: {
					name: '',
					email: '',
					role: ''
				},

                temp_item_category: '',
                temp_item_description: '',
                temp_item_serial_no: '',
                temp_item_size: '',
                temp_item_pinn_conn: '',
                temp_item_box_conn: '',
                temp_item_SP: '',
                temp_item_RW: '',
                temp_item_dirty: '',
                temp_item_comments: '',

				errors: {
					name: '',
					email: '',
					role: ''
				}
			};
		},
        mounted(){
            this.temp_item_id = this.item.inventory_id;
            this.temp_item_category = this.item.category;
            this.temp_item_description = this.item.description;
            this.temp_item_serial_no = this.item.serial_no;
            this.temp_item_size = this.item.size;
            this.temp_item_pinn_conn = this.item.pinn_conn;
            this.temp_item_box_conn = this.item.box_conn;
            this.temp_item_SP = this.item.SP;
            this.temp_item_RW = this.item.RW;
            this.temp_item_dirty = this.item.dirty;
            this.temp_item_comments = this.item.comments;
        },
        computed: {
            new_item(){
                return {
                    'inventory_id': this.temp_item_id,
                    'category': this.temp_item_category,
                    'description': this.temp_item_description,
                    'serial_no': this.temp_item_serial_no,
                    'size': this.temp_item_size,
                    'pinn_conn': this.temp_item_pinn_conn,
                    'box_conn': this.temp_item_box_conn,
                    'SP': this.temp_item_SP,
                    'RW': this.temp_item_RW,
                    'dirty': this.temp_item_dirty,
                    'comments': this.temp_item_comments
                }
            },
            filtered_description_list(){

                if([null, false, ''].includes(this.temp_item_category)) return [];
                return this.description_list.list.filter(x => x.parent_configuration_id == this.temp_item_category);
            }, 
            ...mapGetters(['inventory_list', 'user', 'loading', 'size_list', 'category_list', 'connector_list', 'description_list'])
        },
		methods: {
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			close(){
				this.$emit('close');
			},
			action(){
				store.dispatch(EDIT_INVENTORY_ITEM, this.new_item).then(() => {
                    
                    this.$emit('updated',this.new_item);
				}).catch(message => {
                    this.error = message;
				}).finally(() => {
					
				});
                
				
			},
		}
	}
</script>